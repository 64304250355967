import * as React from 'react'

const Hero = () => {
  return (
    <div className="c-hero" style={{backgroundImage: 'url(/hero.jpg)'}}>
      <div className="c-hero__box">
        <span className='c-hero__above-heading'>Brodnica</span>
        <h1 className='c-hero__heading'>
          Instalacje sanitarno-grzewcze
        </h1>
      </div>
    </div>
  )
}

export default Hero