import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import TextImage from "../components/text-image"
import CTA from '../components/cta'

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Hero />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Hero />
      <TextImage
        imgUrl="/home-image.jpg"
        heading="Systemy grzewcze Brodnica"
        text="Zastanawiasz się jak zoptymalizować system ogrzewania w domu? <br>
Oferujemy kompleksową diagnozę i doradztwo w zakresie systemów grzewczych.<br><br>
Posiadamy poparte doświadczeniem i ciągle doskonalone do najnowszych rozwiązań szerokie umiejętności w dziedzinie ogrzewania.<br><br>
Dzięki profesjonalnej analizie dowiesz się czy warto naprawiać dotychczasowy piec czy lepszym rozwiązaniem byłaby wymiana systemu ogrzewania na inny.
"
        btn={{
          to: '/uslugi',
          text: 'Zobacz Usługi'
        }}
      />
      <CTA />
    </Layout>
  )
}

export default Home

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Usługi grzewcze" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
